<template>
    <AutoComplete
        :id="`${rule.id}_channel`"
        :name="`${rule.id}_channel`"
        :items="channelAutoCompleteItems"
        label="Channel"
        placeholder="Search your channel"
        :value="selectedChannel"
        :icon-color="iconColor"
        :is-async="true"
        @input="onInput"
        @query="onSearch"
    />
</template>

<script>
import AutoComplete from '@/components/forms/AutoComplete';
import colors from '@styles/abstracts/_variables.colors.scss';

export default {
    name: 'AttributeChannel',
    components: {
        AutoComplete,
    },
    props: {
        rule: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            channelOptions: [],
            selectedChannel: '',
        };
    },
    computed: {
        iconColor: () => colors['first-primary-color'],
        channelAutoCompleteItems() {
            return this.channelOptions.map((opt) => opt.text);
        },
    },
    async mounted() {
        if (this.rule && this.rule.value) {
            this.channelOptions = await this.rule.getContent({ code: this.rule.value });
            const selectedChannel = this.findChannelOption('id', this.rule.value);
            this.selectedChannel = selectedChannel.text;
        }
    },
    methods: {
        findChannelOption(key, value) {
            return this.channelOptions.find((opt) => opt[key] === value);
        },
        onInput(channelName, channelIndex) {
            const channel = this.channelOptions[channelIndex];
            if (channel) {
                this.$emit('input', channel.id);
            }
        },
        async onSearch(query) {
            if (!query) {
                this.channelOptions = [];
            } else {
                this.channelOptions = await this.rule.getContent({ query });
            }
        },
    },
};
</script>
